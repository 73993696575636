import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  setting: icon('ic_setting'),
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const { onBoarded, vehicleOnBoarded } = useAuthContext();

  const data = useMemo(
    () =>
      onBoarded && vehicleOnBoarded
        ? [
            {
              subheader: t('Fleet owner'),
              items: [
                {
                  title: t('Home'),
                  path: paths.dashboard.root,
                  icon: ICONS.dashboard,
                },
                {
                  title: t('vehicles'),
                  path: paths.dashboard.product.root,
                  icon: ICONS.product,
                  children: [
                    { title: t('list'), path: paths.dashboard.product.root },
                    {
                      title: t('details'),
                      path: paths.dashboard.product.demo.details,
                    },
                    { title: t('create'), path: paths.dashboard.product.new },
                    { title: t('edit'), path: paths.dashboard.product.demo.edit },
                  ],
                },
                {
                  title: t('service requests'),
                  path: paths.dashboard.order.root,
                  icon: ICONS.dashboard,
                  children: [
                    { title: t('list'), path: paths.dashboard.order.root },
                    { title: t('details'), path: paths.dashboard.order.demo.details },
                  ],
                },
              ],
            },
          ]
        : [
            {
              subheader: t('Fleet owner'),
              items: [
                {
                  title: t('Setup'),
                  path: paths.dashboard.root,
                  icon: ICONS.setting,
                },
              ],
            },
          ],
    [t, onBoarded, vehicleOnBoarded]
  );

  return data;
}
