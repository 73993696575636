import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import { AppLayout } from 'src/layouts/app';
// components
import { LoadingScreen, SplashScreen } from 'src/components/loading-screen';
import CompactLayout from 'src/layouts/compact/layout';

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/app'));
// VEHICLES
const VehiclesDetailsPage = lazy(() => import('src/pages/app/vehicles/details'));
const VehiclesListPage = lazy(() => import('src/pages/app/vehicles/list'));
const VehiclesCreatePage = lazy(() => import('src/pages/app/vehicles/new'));
const VehiclesEditPage = lazy(() => import('src/pages/app/vehicles/edit'));
// SERVICE REQUESTS
const ServiceRequestDetailsPage = lazy(() => import('src/pages/app/service-requests/details'));
const ServiceRequestListPage = lazy(() => import('src/pages/app/service-requests/list'));
const ServiceRequestCreatePage = lazy(() => import('src/pages/app/service-requests/new'));
const ServiceRequestEditPage = lazy(() => import('src/pages/app/service-requests/edit'));
// BLANK PAGE
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));

export const appRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <AppLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AppLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'fleet-owner',
        children: [
          {
            path: 'vehicles',
            children: [
              { path: '', element: <VehiclesListPage /> },
              { path: 'new', element: <VehiclesCreatePage /> },
              { path: ':id/edit', element: <VehiclesEditPage /> },
              { path: ':id', element: <VehiclesDetailsPage /> },
            ],
          },
          {
            path: 'service-requests',
            children: [
              { path: '', element: <ServiceRequestListPage /> },
              { path: 'new', element: <ServiceRequestCreatePage /> },
              { path: ':id/edit', element: <ServiceRequestEditPage /> },
              { path: ':id', element: <ServiceRequestDetailsPage /> },
            ],
          },
        ],
      },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
