export const makes = [
  { code: '', label: '', phone: '' },
  { code: 'HZA 270', label: 'HZA 270' },
  {
    code: 'BCJ 8174',
    label: 'BCJ 8174',
  },
  {
    code: 'JHV 996',
    label: 'JHV 996',
  },
];
