export const models = [
  { code: '', label: '', phone: '' },
  { code: 'Scania T-100', label: 'Scania T-100' },
  {
    code: 'Scania T-400',
    label: 'Scania T-400',
  },
  {
    code: 'Scania W-600',
    label: 'Scania W-600',
  },
];
