import { Navigate, useRoutes } from 'react-router-dom';
import { authRoutes } from './auth';
import { appRoutes } from './app';
import { dashboardRoutes } from './dashboard';

export default function Router() {
  return useRoutes([
    ...authRoutes,
    ...dashboardRoutes,
    ...appRoutes,

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
